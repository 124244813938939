import {
  FREE_SENSAY_PLAN,
  type Frequency,
  FrequencySchema,
  type SensayPlan,
  type StripePlan,
  stripeProductsToPlans,
} from '@/app/pricing/[[...slugs]]/stripe-plans'
import { isNumber } from 'lodash'
import type { Session } from 'next-auth'

import Stripe from 'stripe'
import { DEFAULT_FREQUENCY } from './customer'

export const stripe = new Stripe(process.env.STRIPE_SECRET_KEY, {
  // https://github.com/stripe/stripe-node#configuration
  apiVersion: '2024-06-20',
  typescript: true,
})

export const getCustomerSubscriptions = async (customerId: string) => {
  try {
    const subscriptions = await stripe.subscriptions.list({
      customer: customerId,
    })
    return subscriptions
  } catch (e) {
    console.log(e)
    throw e
  }
}

export const getProduct = async (productId: string) => {
  try {
    const product = await stripe.products.retrieve(productId)
    return product
  } catch (e) {
    console.log(e)
    throw e
  }
}

type StripePrice = Stripe.Price & {
  product: Stripe.Product
}

type GroupedPrices = {
  [key: string]: StripePrice[]
}

export type ProductWithPrices = Stripe.Product & {
  prices: GroupedPrices
}

export const getAllActiveProducts = async (): Promise<Stripe.Product[]> => {
  try {
    const response = await stripe.products.list({
      active: true,
      type: 'service',
    })
    return response.data
  } catch (e) {
    console.error('Error fetching products:', e)
    throw e
  }
}

export const getProductPrices = async (productId: string): Promise<GroupedPrices> => {
  try {
    const response = await stripe.prices.list({
      active: true,
      product: productId,
    })

    const groupedPrices = response.data.reduce<GroupedPrices>((acc, price) => {
      if (price.recurring) {
        const interval = price.recurring.interval
        if (!acc[interval]) {
          acc[interval] = []
        }
        acc[interval].push(price as StripePrice)
      }
      return acc
    }, {})

    return groupedPrices
  } catch (e) {
    console.error('Error fetching prices for products', e)
    throw e
  }
}

export const getAllProductsWithPrices = async (): Promise<ProductWithPrices[]> => {
  const products = await getAllActiveProducts()

  const productsWithPrices = await Promise.all(
    products.map(
      async (product): Promise<ProductWithPrices> => ({
        ...product,
        prices: await getProductPrices(product.id),
      }),
    ),
  )

  return productsWithPrices
}

export const productIdToPlan = ({ product_id }: { product_id: string | null }): SensayPlan => {
  const plan =
    product_id && product_id in stripeProductsToPlans
      ? (stripeProductsToPlans[product_id] ?? FREE_SENSAY_PLAN)
      : FREE_SENSAY_PLAN
  return plan
}

export const calculatePrice = ({
  stripePlan,
  frequency,
  session,
  currentPlan,
  products,
}: {
  stripePlan: StripePlan
  frequency: Frequency
  session: Session | null
  currentPlan: SensayPlan | ''
  products: ProductWithPrices[]
}) => {
  const { price } = stripePlan[frequency]

  const product = products.find((product) => stripePlan.plan === stripeProductsToPlans[product.id])
  const productPrice = product?.prices?.[frequency][0].unit_amount

  const userPlanFrequency = session?.customer?.frequency || undefined
  const validFrequency = userPlanFrequency === frequency
  const isUserPlan_v2 = currentPlan === stripePlan.plan && validFrequency
  const customPrice = isUserPlan_v2 ? session?.customer?.price : undefined

  if (isNumber(customPrice)) {
    return customPrice?.toString() || '0'
  }

  if (productPrice) {
    return (productPrice / 100 / (frequency === 'year' ? 12 : 1)).toString()
  }

  return price
}

export async function getSubscriptionDataFromStripeSessionId(stripeSessionId: string) {
  const stripeSession = await stripe.checkout.sessions.retrieve(stripeSessionId)
  const subscription = await stripe.subscriptions.retrieve(stripeSession.subscription as string)
  const product = subscription.items.data[0].price.product
  const productId = (typeof product === 'string' ? product : product?.id) || null
  const sensayPlan = productIdToPlan({ product_id: productId })

  // @ts-ignore
  const interval = subscription.plan?.interval

  return {
    // we're not using session.customer.plan here, because it might not be updated through the webhook yet.
    plan: sensayPlan,
    frequency: (FrequencySchema.parse(interval) as Frequency) || DEFAULT_FREQUENCY,
    price: (stripeSession.amount_total || 0) / 100,
  }
}
