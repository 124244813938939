import {
  FREE_NOT_SIGNED_IN_MAX_CHAT_MESSAGES,
  FREE_SENSAY_PLAN,
  type SensayPlan,
  SensayPlanSchema,
  featureCategories,
  getFilesPerPlan,
  validProPlans,
} from '@/app/pricing/[[...slugs]]/stripe-plans'
import type { Session } from 'next-auth'
import { type CustomerData, getCustomerData } from './customer'

export const hasProPlan = (customer: Session['customer']) => {
  return validProPlans.includes(customer?.plan || FREE_SENSAY_PLAN)
}

export const throwIfNotValidCustomer = (customer: Session['customer'], shouldBePro = true) => {
  if (!customer) {
    throw new Error('User is not a customer.')
  }
  if (!customer.plan) {
    throw new Error("User doesn't have a valid plan.")
  }
  if (shouldBePro && !hasProPlan(customer)) {
    throw new Error('User needs a pro plan for this feature.')
  }
}

type FeatureCategoryKey = keyof typeof featureCategories
export type FeatureKey<T extends FeatureCategoryKey> = keyof (typeof featureCategories)[T]
export type TrainingAndInteractionList = keyof (typeof featureCategories)['Training & Interaction']

export function getFeatureDetails<
  T extends FeatureCategoryKey,
  F extends FeatureKey<T>,
  P extends keyof (typeof featureCategories)[T][F],
>(category: T, feature: F, plan: P) {
  return featureCategories[category][feature][plan]
}

export async function isAllowedToUseFeature(
  context: { sessionCustomer: Session['customer'] } | { userId: string },
  feature: TrainingAndInteractionList,
) {
  if ('sessionCustomer' in context) {
    if (!context.sessionCustomer) {
      return false
    }
    return getFeatureDetails('Training & Interaction', feature, context.sessionCustomer.plan)
  }
  if (context.userId) {
    const customer = await getCustomerData(context.userId)
    if (!customer) {
      throw new Error('Customer not found')
    }

    return getFeatureDetails('Training & Interaction', feature, customer.plan)
  }

  throw new Error('No user context provided')
}

export function plansRequiredForFeature<T extends FeatureCategoryKey, F extends FeatureKey<T>>(
  category: T,
  feature: F,
) {
  const plans = featureCategories[category][feature]
  if (!plans) {
    return []
  }
  return (
    Object.entries(plans)
      // we need to filter out entries w/ the false values or non-sub-related keys (i.e. comingSoon)
      .filter(([planKey, value]) => SensayPlanSchema.safeParse(planKey).success && !!value)
      .map(([planKey]) => planKey as SensayPlan)
  )
}

export function getMinimumPlanForFeature<T extends FeatureCategoryKey, F extends FeatureKey<T>>(
  category: T,
  feature: F,
) {
  return plansRequiredForFeature(category, feature)[0]
}

export async function canUserAccessManager(customer: CustomerData, manager: TrainingAndInteractionList) {
  return {
    canAccess: await isAllowedToUseFeature({ sessionCustomer: customer }, manager),
    minimumPlan: getMinimumPlanForFeature('Training & Interaction', manager),
  }
}

export function userExceedChatLimit(chatHistoryLength: number, customerPlan: SensayPlan | null) {
  const limit = customerPlan
    ? getFeatureDetails('Messaging & Storage', 'Messages per Day', customerPlan)
    : FREE_NOT_SIGNED_IN_MAX_CHAT_MESSAGES * 2 // * 2 so that the replica's messages are included

  return chatHistoryLength >= limit
}

export function userExceedsFile(customerPlan: SensayPlan, filesLength: number) {
  const allowedFiles = getFilesPerPlan(customerPlan, false)

  if (typeof allowedFiles === 'number') return filesLength >= allowedFiles
  if (typeof allowedFiles === 'boolean') return allowedFiles
  if (typeof allowedFiles === 'string') return true // If it's string then it's the custom option
}

export type CanUserAccessManagers = Awaited<ReturnType<typeof canUserAccessManagers>>

export async function canUserAccessManagers(customer: CustomerData) {
  return {
    filesAndLinks: await canUserAccessManager(customer, 'Train with Files & Links'),
    voiceAndVideo: await canUserAccessManager(customer, 'Voice Interactions'),
    discord: await canUserAccessManager(customer, 'Discord Copilot'),
    telegram: await canUserAccessManager(customer, 'Telegram Copilot'),
  }
}
