import { type Frequency, FrequencySchema, type SensayPlan } from '@/app/pricing/[[...slugs]]/stripe-plans'
import { kv } from '@vercel/kv'
import type Stripe from 'stripe'
import { ENV } from './get-deployment-url'
import {
  NAMESPACE_CUSTOMERS_IDS_LIVE,
  NAMESPACE_CUSTOMERS_IDS_TEST,
  NAMESPACE_CUSTOMERS_LIVE,
  NAMESPACE_CUSTOMERS_TEST,
  initKv,
} from './kv'
import { productIdToPlan, stripe } from './stripe'
import type { User } from './types/supabase'

const DEFAULT_PRICE = 0
export const DEFAULT_FREQUENCY = '' as const

export const customerKv = initKv<CustomerKV>(ENV === 'production' ? NAMESPACE_CUSTOMERS_LIVE : NAMESPACE_CUSTOMERS_TEST)
export const customerIDsKv = initKv<string>(
  ENV === 'production' ? NAMESPACE_CUSTOMERS_IDS_LIVE : NAMESPACE_CUSTOMERS_IDS_TEST,
)

async function getCustomersCache(userId: string) {
  const keys = [
    `${NAMESPACE_CUSTOMERS_LIVE}:${userId}`,
    `${NAMESPACE_CUSTOMERS_TEST}:${userId}`,
    `${NAMESPACE_CUSTOMERS_IDS_LIVE}:${userId}`,
    `${NAMESPACE_CUSTOMERS_IDS_TEST}:${userId}`,
  ]

  const [_customerLive, _customerTest, _customerIdLive, _customerIdTest] =
    await kv.mget<[CustomerKV | null, CustomerKV | null, string | null, string | null]>(keys)

  const customerId = ENV === 'production' ? _customerIdLive : _customerIdTest

  // customer from dev env is used also in production if it exists
  const customer = ENV === 'production' ? _customerTest || _customerLive : _customerTest

  return {
    customer,
    customerId,
  }
}

export type CustomerKV = {
  plan: SensayPlan
  price: number
  frequency: Frequency | ''
}

// if you pass true to CustomerData, you say that customerId will not be undefined
export type CustomerData<CustomerId = boolean> = CustomerKV & {
  customerId: CustomerId extends true ? string : string | undefined
}

async function searchStripeCustomerByMetadata(userId: User['id']) {
  const { data: stripeCustomers } = await stripe.customers.search({
    query: `metadata[\'user_id\']:\'${userId}\'`,
  })

  return stripeCustomers.at(0)
}

export async function getStripeCustomer(customerId: string): Promise<Stripe.Customer | undefined> {
  const stripeCustomer = await stripe.customers.retrieve(customerId)

  if (!stripeCustomer || stripeCustomer.deleted) {
    return undefined
  }

  return stripeCustomer
}

function getFreeCustomerData(customerId: string | undefined): CustomerData {
  const plan = productIdToPlan({ product_id: null })

  return {
    plan,
    customerId,
    price: DEFAULT_PRICE,
    frequency: DEFAULT_FREQUENCY,
  }
}

async function stripeCustomerToCustomerData(stripeCustomer: Stripe.Customer): Promise<CustomerData<true>> {
  const customerId = stripeCustomer.id
  const subscriptions = await stripe.subscriptions.list({
    customer: stripeCustomer.id,
  })

  const subscriptionCouponPercentage = subscriptions.data.at(0)?.discount?.coupon.percent_off

  const subscriptionPrice = subscriptions.data.at(0)?.items.data.at(0)?.price

  const product = subscriptionPrice?.product
  const productId = (typeof product === 'string' ? product : product?.id) ?? null
  const frequency = subscriptionPrice?.recurring?.interval || DEFAULT_FREQUENCY

  const discountAmount = (subscriptionPrice?.unit_amount || 0 * (subscriptionCouponPercentage || 0)) / 100 || 0
  const price = (subscriptionPrice?.unit_amount || 0) / 100 - discountAmount

  const monthlyPrice = price / (frequency === 'year' ? 12 : 1)

  const plan = productIdToPlan({ product_id: productId })

  return {
    plan,
    price: monthlyPrice,
    frequency: FrequencySchema.parse(frequency),
    customerId,
  }
}

export async function getCustomerData(userId: User['id'], searchIfNoCustomerId = false): Promise<CustomerData> {
  const customerCache = await getCustomersCache(userId)

  if (customerCache.customer && (customerCache.customerId || !searchIfNoCustomerId)) {
    return {
      ...customerCache.customer,
      customerId: customerCache.customerId || undefined,
    }
  }

  const stripeCustomer = customerCache.customerId
    ? await getStripeCustomer(customerCache.customerId)
    : await searchStripeCustomerByMetadata(userId)

  if (!stripeCustomer) {
    return getFreeCustomerData(undefined)
  }

  await customerIDsKv.set(userId, stripeCustomer.id)

  const customerData = await stripeCustomerToCustomerData(stripeCustomer)
  await customerKv.set(userId, customerData)

  return customerData
}

export async function createCustomer(user: User): Promise<CustomerData<true>> {
  const newStripeCustomer = await stripe.customers.create({
    email: user.email || undefined,
    name: user.name || undefined,
    metadata: { user_id: user.id },
  })

  const customerData = await stripeCustomerToCustomerData(newStripeCustomer)

  await customerKv.set(user.id, customerData)
  if (customerData.customerId) {
    await customerIDsKv.set(user.id, customerData.customerId)
  }

  return customerData
}

export async function getOrCreateCustomer(user: User, searchIfNoCustomerId = false): Promise<CustomerData<true>> {
  const customerData = await getCustomerData(user.id, searchIfNoCustomerId)

  if (customerData?.customerId) {
    return customerData as CustomerData<true>
  }

  return createCustomer(user)
}
