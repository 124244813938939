import { memo } from 'react'
import { twMerge } from 'tailwind-merge'

const ReplicaTypingIndicator = memo(
  ({
    className,
    name,
    isTyping,
  }: {
    className?: string
    name: string
    isTyping: boolean
  }) => {
    return (
      <div className={twMerge('w-full text-xs', className)}>
        <p
          className={twMerge(
            '-mb-1 flex flex-row items-baseline gap-2 text-sm opacity-0 duration-300 md:mb-0',
            isTyping && 'mb-2 opacity-80 md:mb-2',
          )}
        >
          <span>
            <span className="font-semibold">{name}</span> is typing
          </span>
          <span className="flex flex-row gap-1">
            <span className="h-0.5 w-0.5 animate-[dotbounce_1s_ease-out_0ms_infinite] rounded-full bg-[#838079]" />
            <span className="h-0.5 w-0.5 animate-[dotbounce_1s_ease-out_200ms_infinite] rounded-full bg-[#838079]" />
            <span className="h-0.5 w-0.5 animate-[dotbounce_1s_ease-out_400ms_infinite] rounded-full bg-[#838079]" />
          </span>
        </p>
      </div>
    )
  },
)

export default ReplicaTypingIndicator
