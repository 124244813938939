import { ServerActionError } from '@/server-actions/wrapServerAction'
import { captureException } from '@sentry/browser'
import { ZodError } from 'zod'
import { create } from 'zustand'

type ChatReplicaErrorState = {
  error: { friendlyError: string; displayError: string; sentryId: string | null; stack: string } | null
  setError: ({ friendlyError, error }: { friendlyError: string; error: unknown }) => void
  resetError: () => void
}

const useChatReplicaError = create<ChatReplicaErrorState>()((set) => ({
  error: null,
  resetError: () => set({ error: null }),
  setError: ({ friendlyError: _friendlyError, error }) => {
    // TODO: Capture error

    let displayError = 'An unexpected error occurred.'
    let sentryId: string | null = null
    let stack = 'N/A'
    let friendlyError = _friendlyError

    if (error instanceof ServerActionError) {
      if (error.friendlyMessage) {
        friendlyError = error.friendlyMessage
      }
      displayError = error.message
      sentryId = error.sentryId
      stack = error.stack || 'N/A'
    } else if (error instanceof ZodError) {
      displayError = error.errors.map((err) => `${err.path.join('.')}: ${err.message}`).join('\n')
      sentryId = captureException(error)
    } else if (error instanceof Error) {
      displayError = error.message
      stack = error.stack || 'N/A'
      sentryId = captureException(error)
    } else if (typeof error === 'string') {
      displayError = error
      sentryId = captureException(error)
    }

    set({ error: { friendlyError, displayError, sentryId, stack } })
  },
}))

export default useChatReplicaError
