
import { callServer } from 'next/dist/client/app-call-server'

function __build_action__(action, args) {
  return callServer(action.$$id, args)
}

/* __next_internal_action_entry_do_not_use__ {"2a0b0ac3fa8fe50adc100ffb956c755207d86e89":"$$ACTION_2","4b2dd2b7e1dbbf62da166ae4279a722d64468d5b":"getUserReplicaMessagesForPastDay","5491cb3bb25f89bc24b84fc8b4842f1ca7d7114b":"$$ACTION_0","663bf782997edd29892d4ed0e4e42775232d111c":"validateAndGetReplicaSlugAvailability","7c8326f6c12c93ba10b5bec493af5a8bbae5b8c9":"canUserCreateAPublicAndPrivateReplicasServerAction","8184d45ce7af69090d1efce9da6eb35f077bfd33":"validateAndUpdateVoiceManager","9c67c1056b55c52e817b591200281b458f7a084f":"$$ACTION_1","ad1d3e70c06f1d9891d0c7129b753359fd7f48fd":"validateAndGetReplica"} */ export var getUserReplicaMessagesForPastDay = createServerReference("4b2dd2b7e1dbbf62da166ae4279a722d64468d5b");
import { createServerReference } from "private-next-rsc-action-client-wrapper";
export var validateAndGetReplicaSlugAvailability = createServerReference("663bf782997edd29892d4ed0e4e42775232d111c");
export var validateAndGetReplica = createServerReference("ad1d3e70c06f1d9891d0c7129b753359fd7f48fd");
export var validateAndUpdateVoiceManager = createServerReference("8184d45ce7af69090d1efce9da6eb35f077bfd33");
export var canUserCreateAPublicAndPrivateReplicasServerAction = createServerReference("7c8326f6c12c93ba10b5bec493af5a8bbae5b8c9");

