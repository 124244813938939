
import { callServer } from 'next/dist/client/app-call-server'

function __build_action__(action, args) {
  return callServer(action.$$id, args)
}

/* __next_internal_action_entry_do_not_use__ {"1093c2d665e27f6cce299fc77514d46989d2fb48":"getReplicaHeygenCreditsData","1a9905fbb68cd3db2fe6c36f60d6005b92d6c494":"deleteCustomHeygenReplicaCredentials","2256c83e8b949bbf8a686881342735361fc2cc4f":"getReplicaHeygenApiKeyAndAvatarId","4902792ab3757bdb9051b638647066fc53b58f37":"getIsReplicaInteractiveAvatarEnabledServerAction","88ab2378e4e87900d43404551b7eafa282793caa":"getHeygenAvatarServerAction","9ec273581d7baf0fe21a44e2516298b39e9efac8":"deleteCustomHeygenStreamingAvatarId","b92cf42121675aa61f5a047aa52b891e648e8a02":"getReplicaHeygenDataServerAction","cb9847cd71f698feba248beb441054b1297f900e":"setReplicaHeygenApiKey","d38eab086644f94458b1a23736f7ce337cd5c167":"updateHeygenUserAvatar","dbc8baee375fa7d81d2b21372b06db9219bcd8d2":"$$ACTION_0"} */ export var getReplicaHeygenCreditsData = createServerReference("1093c2d665e27f6cce299fc77514d46989d2fb48");
import { createServerReference } from "private-next-rsc-action-client-wrapper";
export var setReplicaHeygenApiKey = createServerReference("cb9847cd71f698feba248beb441054b1297f900e");
export var deleteCustomHeygenReplicaCredentials = createServerReference("1a9905fbb68cd3db2fe6c36f60d6005b92d6c494");
export var deleteCustomHeygenStreamingAvatarId = createServerReference("9ec273581d7baf0fe21a44e2516298b39e9efac8");
export var getReplicaHeygenApiKeyAndAvatarId = createServerReference("2256c83e8b949bbf8a686881342735361fc2cc4f");
export var getIsReplicaInteractiveAvatarEnabledServerAction = createServerReference("4902792ab3757bdb9051b638647066fc53b58f37");
export var getHeygenAvatarServerAction = createServerReference("88ab2378e4e87900d43404551b7eafa282793caa");
export var updateHeygenUserAvatar = createServerReference("d38eab086644f94458b1a23736f7ce337cd5c167");
export var getReplicaHeygenDataServerAction = createServerReference("b92cf42121675aa61f5a047aa52b891e648e8a02");

