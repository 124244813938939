
import { callServer } from 'next/dist/client/app-call-server'

function __build_action__(action, args) {
  return callServer(action.$$id, args)
}

/* __next_internal_action_entry_do_not_use__ {"132b4752c80834a0af8e7993a0eea80d6bb5dae2":"$$ACTION_0","48d5a23eae291f1172b7011ccae7c2f30614ae5a":"getTrainingAnswerSuggestions"} */ export var getTrainingAnswerSuggestions = createServerReference("48d5a23eae291f1172b7011ccae7c2f30614ae5a");
import { createServerReference } from "private-next-rsc-action-client-wrapper";

