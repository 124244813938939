'use client'

import { Alert } from '@/components/Alert'
import { type ReactNode, useEffect, useRef } from 'react'
import useStudioStore from './useStudioStore'

export default function StudioError({ extra }: { extra?: ReactNode }) {
  const { warning, resetWarning } = useStudioStore()

  const alertRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (alertRef.current) {
      alertRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
    }
  }, [alertRef.current])

  if (!warning) {
    return
  }

  return (
    <Alert
      hideLinkBtn
      heading="Warning"
      actionBtn={{
        text: 'Dismiss',
        handleActionBtn: resetWarning,
      }}
      type="warning"
      ref={alertRef}
    >
      <p>{warning.friendlyWarning}</p>

      {extra}
    </Alert>
  )
}
