import BigNumber from 'bignumber.js'
import z from 'zod'

export const STARTER_SENSAY_PLAN = 'starter'
export const PROFESSIONAL_SENSAY_PLAN = 'professional'
export const ENTERPRISE_SENSAY_PLAN = 'enterprise'
export const FREE_SENSAY_PLAN = 'free'

export const frequencies = ['month', 'year'] as const

export const SALES_EMAIL = 'mailto:sales@sensay.io'

export type Frequency = (typeof frequencies)[number]

export const FREE_NOT_SIGNED_IN_MAX_CHAT_MESSAGES = 6
const FREE_SIGNED_IN_MAX_CHAT_MESSAGES = 50
const STARTER_MAX_CHAT_MESSAGES = 1000
const PRO_MAX_CHAT_MESSAGES = 5000

export function isCurrentPlan({
  currentPlan,
  plan,
  planFrequency,
  userPlanFrequency,
}: {
  currentPlan: SensayPlan | ''
  plan: SensayPlan
  planFrequency: Frequency
  userPlanFrequency: Frequency | undefined | ''
}) {
  if (currentPlan === FREE_SENSAY_PLAN && plan === currentPlan) return true

  return currentPlan === plan && planFrequency === userPlanFrequency
}

export const FrequencyStrictSchema = z.enum(['month', 'year'])
export const FrequencySchema = z.enum(['month', 'year', ''])

export const stripeProductsToPlans: Record<string, SensayPlan> = {
  // production
  prod_QlPZ4ohqwMW9rn: STARTER_SENSAY_PLAN,
  prod_QlPZUFSLJvrxxt: PROFESSIONAL_SENSAY_PLAN,

  // legacy
  prod_QBRSGY2JeSilpf: PROFESSIONAL_SENSAY_PLAN,
  prod_QBRSQTPtA3lGAB: PROFESSIONAL_SENSAY_PLAN, // ok with flowtrade customer was Pro Voice
  prod_QBRS8cQyQzabH2: PROFESSIONAL_SENSAY_PLAN,
  prod_QBRSCbJOGwaL4w: PROFESSIONAL_SENSAY_PLAN, // ok with pictographs.io and arqexchange.io customer was Pro
  prod_Oty5PkV1VwZWVQ: PROFESSIONAL_SENSAY_PLAN, // ok with customers was Your Replica
  prod_OjBjFU7AgDsTo6: STARTER_SENSAY_PLAN, // ok with customers was premium

  // test env
  prod_QlLS8bxspfGx5o: STARTER_SENSAY_PLAN,
  prod_QlLUup7wieRVwm: PROFESSIONAL_SENSAY_PLAN,
}

export type SensayPlan = z.infer<typeof SensayPlanSchema>

const sensayPlans = [FREE_SENSAY_PLAN, STARTER_SENSAY_PLAN, PROFESSIONAL_SENSAY_PLAN, ENTERPRISE_SENSAY_PLAN] as const
export const SensayPlanSchema = z.enum(sensayPlans)

export const validProPlans: SensayPlan[] = [PROFESSIONAL_SENSAY_PLAN, ENTERPRISE_SENSAY_PLAN]

const REPLICAS_LIMIT_FREE = 1
const REPLICAS_LIMIT_STARTER = 3
const REPLICAS_LIMIT_PRO = 10
const REPLICAS_LIMIT_ENTERPRISE = Number.POSITIVE_INFINITY

export function getHigherPlan(plan: SensayPlan): SensayPlan | undefined {
  switch (plan) {
    case FREE_SENSAY_PLAN:
      return STARTER_SENSAY_PLAN
    case STARTER_SENSAY_PLAN:
      return PROFESSIONAL_SENSAY_PLAN
    case PROFESSIONAL_SENSAY_PLAN:
      return ENTERPRISE_SENSAY_PLAN
    case ENTERPRISE_SENSAY_PLAN:
      return undefined
  }
}

// const COMMUNITY_TELEGRAM_LINK = 'https://t.me/asksensay'

export type StripePlan = {
  plan: SensayPlan
  title: string
  iconPath: string
  detail: string
  textColor: string
  bgColor: string
  features: string[]
  featuresTitle: string
  month: {
    yearlyDiscount?: string
    price: string
    liveId: string
    testId: string
  }
  year: {
    yearlyDiscount?: string
    price: string
    liveId: string
    testId: string
  }
  gtmId: string
}

const FEATURE_LANGAUGES = '100+ Languages' as const
const FEATURE_TRAINING_ASSISTANT = 'Training Assistant' as const
const FEATURE_DATA_ENCRYPTION = 'Data Encryption' as const
// const FEATURE_EARN_WITH_REFERRALS = 'Earn with Referrals' as const
const FEATURE_COMMUNITY_ACCESS = 'Community Access' as const
const FEATURE_PRIVATE_REPLICAS = 'Private Replicas' as const
const FEATURE_TRAIN_WITH_FILES_AND_LINKS = 'Train with Files & Links' as const
const FEATURE_QUESTS_TO_EARN_TOKENS = 'Quests to Earn Tokens' as const
const FEATURE_TRAINING_COURSES = 'Training Courses' as const
const FEATURE_AUTOMATED_WORKFLOWS = 'Automated Workflows' as const
const FEATURE_CUSTOM_PERSONALITY = 'Custom Personality' as const
const FEATURE_ACCURACY_TOOLS = 'Accuracy Tools' as const
const FEATURE_CUSTOM_STORAGE = 'Custom Storage' as const
const FEATURE_IMPORT_ELEVENLABS_API_KEY = 'Import ElevenLabs API key' as const
const FEATURE_DISCORD_COPILOT = 'Discord Copilot' as const
const FEATURE_TELEGRAM_COPILOT = 'Telegram Copilot' as const
const FEATURE_VOICE_INTERACTIONS = 'Voice Interactions' as const
const FEATURE_FULL_AUTOMATION = 'Full Automation' as const
const FEATURE_DEDICATED_SUPPORT = 'Dedicated Support' as const
const FEATURE_API_ACCESS = 'API Access' as const
const FEATURE_CUSTOM_INTEGRATIONS = 'Custom Integrations' as const

export const featureCategories = {
  Replicas: {
    Replicas: {
      [FREE_SENSAY_PLAN]: REPLICAS_LIMIT_FREE,
      [STARTER_SENSAY_PLAN]: REPLICAS_LIMIT_STARTER,
      [PROFESSIONAL_SENSAY_PLAN]: REPLICAS_LIMIT_PRO,
      [ENTERPRISE_SENSAY_PLAN]: REPLICAS_LIMIT_ENTERPRISE,
      tooltip: 'Digital assistants that can interact with anyone',
    },
    [FEATURE_PRIVATE_REPLICAS]: {
      [FREE_SENSAY_PLAN]: false,
      [STARTER_SENSAY_PLAN]: true,
      [PROFESSIONAL_SENSAY_PLAN]: true,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: 'Digital assistants accessible only to you or selected users',
    },
  },
  'Messaging & Storage': {
    'Messages per Day': {
      [FREE_SENSAY_PLAN]: FREE_SIGNED_IN_MAX_CHAT_MESSAGES,
      [STARTER_SENSAY_PLAN]: STARTER_MAX_CHAT_MESSAGES,
      [PROFESSIONAL_SENSAY_PLAN]: PRO_MAX_CHAT_MESSAGES,
      [ENTERPRISE_SENSAY_PLAN]: Number.POSITIVE_INFINITY,
      tooltip: 'Number of interactions your replicas can have daily',
      formatter: (value: number | string) =>
        Number.isFinite(value) ? `${BigNumber(value).toFormat()} Messages` : 'Unlimited Messaging',
    },
    'Data Storage': {
      [FREE_SENSAY_PLAN]: false,
      [STARTER_SENSAY_PLAN]: 50,
      [PROFESSIONAL_SENSAY_PLAN]: 200,
      [ENTERPRISE_SENSAY_PLAN]: 'Custom',
      tooltip: 'Amount of files your replicas can learn from',
      formatter: (value: string | number) => (typeof value === 'number' ? `${value} Files` : value),
    },
    [FEATURE_LANGAUGES]: {
      [FREE_SENSAY_PLAN]: true,
      [STARTER_SENSAY_PLAN]: true,
      [PROFESSIONAL_SENSAY_PLAN]: true,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: 'Your replicas can communicate in over 100 languages',
    },
  },
  'Training & Interaction': {
    [FEATURE_TRAINING_ASSISTANT]: {
      [FREE_SENSAY_PLAN]: true,
      [STARTER_SENSAY_PLAN]: true,
      [PROFESSIONAL_SENSAY_PLAN]: true,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: 'Guided tool to help you teach your replica',
    },
    [FEATURE_TRAIN_WITH_FILES_AND_LINKS]: {
      [FREE_SENSAY_PLAN]: false,
      [STARTER_SENSAY_PLAN]: true,
      [PROFESSIONAL_SENSAY_PLAN]: true,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: 'Teach your replica using documents and web pages',
    },
    [FEATURE_VOICE_INTERACTIONS]: {
      [FREE_SENSAY_PLAN]: false,
      [STARTER_SENSAY_PLAN]: false,
      [PROFESSIONAL_SENSAY_PLAN]: true,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: 'Speak with your replica using voice commands',
    },
    [FEATURE_IMPORT_ELEVENLABS_API_KEY]: {
      [FREE_SENSAY_PLAN]: false,
      [STARTER_SENSAY_PLAN]: false,
      [PROFESSIONAL_SENSAY_PLAN]: true,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: 'Use your own ElevenLabs API key to add a custom voice to your replica',
      hidden: true,
    },
    [FEATURE_CUSTOM_PERSONALITY]: {
      [FREE_SENSAY_PLAN]: false,
      [STARTER_SENSAY_PLAN]: false,
      [PROFESSIONAL_SENSAY_PLAN]: true,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: "Tailor your replica's behavior and traits",
    },
    [FEATURE_DISCORD_COPILOT]: {
      [FREE_SENSAY_PLAN]: false,
      [STARTER_SENSAY_PLAN]: false,
      [PROFESSIONAL_SENSAY_PLAN]: true,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: 'Use your replica on Discord for community management',
    },
    [FEATURE_TELEGRAM_COPILOT]: {
      [FREE_SENSAY_PLAN]: false,
      [STARTER_SENSAY_PLAN]: false,
      [PROFESSIONAL_SENSAY_PLAN]: true,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: 'Deploy your replica on Telegram for messaging',
    },
  },
  // "Monetize Your Replica": {
  //   [FEATURE_EARN_WITH_REFERRALS]: {
  //     [FREE_SENSAY_PLAN]: true,
  //     [STARTER_SENSAY_PLAN]: true,
  //     [PROFESSIONAL_SENSAY_PLAN]: true,
  //     [ENTERPRISE_SENSAY_PLAN]: true,
  //     tooltip: "Get rewards when others sign up using your link",
  //   },
  //   "Monetize Your Replica": {
  //     [FREE_SENSAY_PLAN]: false,
  //     [STARTER_SENSAY_PLAN]: true,
  //     [PROFESSIONAL_SENSAY_PLAN]: true,
  //     [ENTERPRISE_SENSAY_PLAN]: true,
  //     tooltip: "Earn money from your replica's interactions",
  //   },
  //   [FEATURE_QUESTS_TO_EARN_TOKENS]: {
  //     [FREE_SENSAY_PLAN]: false,
  //     [STARTER_SENSAY_PLAN]: true,
  //     [PROFESSIONAL_SENSAY_PLAN]: true,
  //     [ENTERPRISE_SENSAY_PLAN]: true,
  //     tooltip: "Complete tasks to earn $SNSY tokens",
  //   },
  // },
  'Automation & Customization': {
    [FEATURE_AUTOMATED_WORKFLOWS]: {
      [FREE_SENSAY_PLAN]: false,
      [STARTER_SENSAY_PLAN]: false,
      [PROFESSIONAL_SENSAY_PLAN]: true,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: 'Set up your replica to perform tasks automatically',
    },
    [FEATURE_FULL_AUTOMATION]: {
      [FREE_SENSAY_PLAN]: false,
      [STARTER_SENSAY_PLAN]: false,
      [PROFESSIONAL_SENSAY_PLAN]: false,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: 'Create complex, fully automated processes with your replicas',
    },
  },
  'Support & Security': {
    [FEATURE_COMMUNITY_ACCESS]: {
      [FREE_SENSAY_PLAN]: true,
      [STARTER_SENSAY_PLAN]: true,
      [PROFESSIONAL_SENSAY_PLAN]: true,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: 'Join our user community for support and collaboration',
    },
    [FEATURE_DEDICATED_SUPPORT]: {
      [FREE_SENSAY_PLAN]: false,
      [STARTER_SENSAY_PLAN]: false,
      [PROFESSIONAL_SENSAY_PLAN]: true,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: 'Get personalized help from our support team',
    },
    [FEATURE_API_ACCESS]: {
      [FREE_SENSAY_PLAN]: false,
      [STARTER_SENSAY_PLAN]: false,
      [PROFESSIONAL_SENSAY_PLAN]: false,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: 'Integrate Sensay features into your own applications',
    },
    [FEATURE_DATA_ENCRYPTION]: {
      [FREE_SENSAY_PLAN]: true,
      [STARTER_SENSAY_PLAN]: true,
      [PROFESSIONAL_SENSAY_PLAN]: true,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: "Keep your replica's data secure and private",
    },
    [FEATURE_ACCURACY_TOOLS]: {
      [FREE_SENSAY_PLAN]: false,
      [STARTER_SENSAY_PLAN]: false,
      [PROFESSIONAL_SENSAY_PLAN]: true,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: "Advanced features to improve your replica's responses",
    },
    [FEATURE_CUSTOM_STORAGE]: {
      [FREE_SENSAY_PLAN]: false,
      [STARTER_SENSAY_PLAN]: false,
      [PROFESSIONAL_SENSAY_PLAN]: false,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: 'Tailored data storage solutions for your needs',
    },
    [FEATURE_TRAINING_COURSES]: {
      [FREE_SENSAY_PLAN]: false,
      [STARTER_SENSAY_PLAN]: true,
      [PROFESSIONAL_SENSAY_PLAN]: true,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: 'Access educational resources to maximize your use of replicas',
    },
  },
} as const

export type FeatureCategory = keyof typeof featureCategories

const getReplicaLimitFeatureString = (limit: number, onlyPublic = false) => {
  if (limit === 1) {
    return onlyPublic ? '1 Public Replica' : '1 Replica'
  }
  if (limit === Number.POSITIVE_INFINITY) {
    return onlyPublic ? 'Unlimited Public Replicas' : 'Unlimited Replicas'
  }

  return `${limit} ${onlyPublic ? 'Public' : ''} Replicas`
}

export const getFilesPerPlan = (plan: SensayPlan, withFormat: boolean) => {
  const feature = featureCategories['Messaging & Storage']['Data Storage'][plan]

  if (withFormat) return `${feature} Files`
  return feature
}

export const stripePlans: Record<SensayPlan, StripePlan> = {
  [FREE_SENSAY_PLAN]: {
    plan: FREE_SENSAY_PLAN,
    title: 'Free',
    iconPath: '/assets/pricing/free-icon.svg',
    detail: 'Start exploring Sensay for free',
    featuresTitle: 'What You Get:',
    features: [
      getReplicaLimitFeatureString(REPLICAS_LIMIT_FREE, true),
      `${FREE_SIGNED_IN_MAX_CHAT_MESSAGES} messages/day`,
      FEATURE_TRAINING_ASSISTANT,
      FEATURE_DATA_ENCRYPTION,
      FEATURE_COMMUNITY_ACCESS,
      FEATURE_LANGAUGES,
      // FEATURE_EARN_WITH_REFERRALS,
    ],
    textColor: 'text-emerald-500',
    bgColor: 'bg-emerald-500 hover:bg-emerald-400 active:bg-emerald-600',
    month: {
      price: '0',
      liveId: '',
      testId: '',
    },
    year: {
      price: '0',
      liveId: '',
      testId: '',
    },
    gtmId: '',
  },
  [STARTER_SENSAY_PLAN]: {
    plan: STARTER_SENSAY_PLAN,
    title: 'Starter',
    iconPath: '/assets/pricing/start-icon.svg',
    detail: 'Create more replicas with enhanced training',
    textColor: 'text-blue-500',
    bgColor: 'bg-blue-500 hover:bg-blue-400 active:bg-blue-600',
    featuresTitle: 'All Free Features, plus:',
    features: [
      getReplicaLimitFeatureString(REPLICAS_LIMIT_STARTER, false),
      `${BigNumber(STARTER_MAX_CHAT_MESSAGES).toFormat()} messages/day`,
      getFilesPerPlan(STARTER_SENSAY_PLAN, true) as string,
      FEATURE_PRIVATE_REPLICAS,
      FEATURE_TRAIN_WITH_FILES_AND_LINKS,
      // 'Monetize your replica',
      FEATURE_QUESTS_TO_EARN_TOKENS,
      FEATURE_TRAINING_COURSES,
    ],
    month: {
      yearlyDiscount: '$150',
      price: '45',
      liveId: 'price_1Q3bMXKuls4YqbSx4pg04do9',
      testId: 'price_1Q3dbMKuls4YqbSxLGlvEY7x',
    },
    year: {
      yearlyDiscount: '$150',
      price: '30',
      liveId: 'price_1Q3c5sKuls4YqbSxrDEgN9pQ',
      testId: 'price_1Q3db2Kuls4YqbSxedEnaPla',
    },
    gtmId: 'starter',
  },
  [PROFESSIONAL_SENSAY_PLAN]: {
    plan: PROFESSIONAL_SENSAY_PLAN,
    title: 'Professional',
    textColor: 'text-primary',
    bgColor: 'bg-primary hover:bg-primary-lighter active:bg-primary-darker',
    iconPath: '/assets/pricing/professional-icon.svg',
    detail: 'Automate and scale your AI workforce',
    featuresTitle: 'All Starter Features, plus:',
    features: [
      getReplicaLimitFeatureString(REPLICAS_LIMIT_PRO, false),
      `${BigNumber(PRO_MAX_CHAT_MESSAGES).toFormat()} messages/day`,
      getFilesPerPlan(PROFESSIONAL_SENSAY_PLAN, true) as string,
      FEATURE_AUTOMATED_WORKFLOWS,
      FEATURE_CUSTOM_PERSONALITY,
      FEATURE_ACCURACY_TOOLS,
      FEATURE_DISCORD_COPILOT,
      FEATURE_TELEGRAM_COPILOT,
      FEATURE_VOICE_INTERACTIONS,
      'Website widget',
    ],
    month: {
      price: '225',
      yearlyDiscount: '$900',
      liveId: 'price_1Ptsk3Kuls4YqbSx8P0hp3gP',
      testId: 'price_1PtonOKuls4YqbSx9NIvXbVp',
    },
    year: {
      price: '150',
      yearlyDiscount: '$900',
      liveId: 'price_1Ptsk3Kuls4YqbSx5zUX9BnD',
      testId: 'price_1PtonOKuls4YqbSx399PRwE8',
    },
    gtmId: 'professional',
  },
  [ENTERPRISE_SENSAY_PLAN]: {
    plan: ENTERPRISE_SENSAY_PLAN,
    title: 'Enterprise',
    iconPath: '/assets/pricing/enterprise-icon.svg',
    detail: 'Custom AI solutions for enterprise scalability',
    featuresTitle: 'All Pro Features, plus:',
    textColor: 'text-violet-500',
    bgColor: 'bg-violet-500 hover:bg-violet-400 active:bg-violet-600',
    features: [
      'Custom Replicas',
      'Unlimited Messaging',
      getFilesPerPlan(ENTERPRISE_SENSAY_PLAN, true) as string,
      FEATURE_CUSTOM_INTEGRATIONS,
      FEATURE_FULL_AUTOMATION,
      FEATURE_DEDICATED_SUPPORT,
      FEATURE_API_ACCESS,
    ],
    month: {
      price: 'Let’s Talk',
      liveId: '',
      testId: '',
    },
    year: {
      price: 'Let’s Talk',
      liveId: '',
      testId: '',
    },
    gtmId: 'talk-to-sales',
  },
}

export type Features = {
  [feature: string]: PlanDetails
}

// biome-ignore lint/suspicious/noExplicitAny:
export type FormatterType = (value: any) => string | number

export type PlanDetails = {
  tooltip: string
  link?: string
  hidden?: boolean
  formatter?: FormatterType
} & Record<SensayPlan, boolean | string | number>
