import type { SensayPlan } from '@/app/pricing/[[...slugs]]/stripe-plans'
import StudioSection from '@/app/studio/StudioSection'
import LinkButton from '@/components/LinkButton'

type StudioUpgradePlanAlertProps = {
  requiredPlan: SensayPlan
}

function UpgradePlanAlert({ requiredPlan }: StudioUpgradePlanAlertProps) {
  return (
    <StudioSection title="You've reached your chat limit" className="bg-white">
      <div>
        You've hit the chat limit for the Free plan, but don't worry—you can still send more messages for now! Upgrade
        to the Starter or Professional plan to unlock more chats, premium features, and get the most out of your
        replicas.
      </div>

      <LinkButton href={`/pricing/year/${requiredPlan}`} primary className="w-fit mx-auto">
        Upgrade plan
      </LinkButton>
    </StudioSection>
  )
}

export default UpgradePlanAlert
