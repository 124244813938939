'use client'

import { CodeBlock } from '@/components/CodeBlock'
import { MemoizedReactMarkdown } from '@/components/Markdown'
import { CircleNotch } from '@phosphor-icons/react'
import Image from 'next/image'
import remarkGfm from 'remark-gfm'
import { twMerge } from 'tailwind-merge'
import { ChatDate } from './ChatDate'
import FavouriteButton from './FavouriteButton'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { isKolzEnv } from '@/app/kolz/[replicaSlug]/utils'
import type { getLastChatHistoryMessageSources } from '@/server-actions/chat'
import type { ServerActionSuccessPayload } from '@/server-actions/wrapServerAction'
import { memo } from 'react'
import type { HistoryMessage } from './Chat'
import ChatImages from './ChatImages'
import { ChunkReferenceLink } from './ChunkReferenceLink'
import MessageButtons from './MessageButtons'

export type Source = ServerActionSuccessPayload<Awaited<ReturnType<typeof getLastChatHistoryMessageSources>>>[0]

type ChatMessageProps = Pick<HistoryMessage, 'id' | 'role' | 'content' | 'context' | 'images' | 'voiceEnabled'> & {
  className?: string
  name: string
  createdAt: string
  profileUrl: string
  isReplicaTyping?: boolean
  replicaSlug: string
  isFavourite?: boolean | undefined
  setIsFavourite: (favourited: boolean) => void
  model: string
  embed?: boolean
  replicaUuid: string
  sources: Source[]
}

const remarkPlugins = [remarkGfm]

const getProfileImageSrc = () => {
  if (isKolzEnv()) {
    return '/assets/user-avatar-kolz.png'
  }
  return '/assets/user-avatar.png'
}

export default memo(function ChatMessage({
  className,
  role,
  name,
  profileUrl,
  content,
  context: _context,
  createdAt,
  isReplicaTyping = false,
  replicaSlug,
  isFavourite,
  setIsFavourite,
  images,
  embed = false,
  voiceEnabled,
  replicaUuid,
  sources,
}: ChatMessageProps) {
  const isUser = role === 'user'

  return (
    <div className={twMerge('flex flex-row gap-3', className)}>
      <div className="relative flex h-[30px] w-[30px] shrink-0 items-center justify-center overflow-clip rounded-full  border-white/50 shadow-md sm:h-[50px] sm:w-[50px]">
        {isUser && !profileUrl && (
          <div className="relative h-[50px] w-[50px] rounded-full">
            <Image alt="profile" src={getProfileImageSrc()} fill className="object-contain" />
          </div>
        )}

        {profileUrl ? (
          <Image
            alt="profile"
            src={profileUrl}
            width={50}
            height={50}
            className="absolute inset-0 m-auto object-contain sm:object-cover"
          />
        ) : (
          !isUser && (
            <div className="flex h-[50px] w-[50px] items-center justify-center bg-[#eeeeee]">
              <CircleNotch size={16} color="#000000" className="animate-spin" />
            </div>
          )
        )}
      </div>
      <div className="flex w-full flex-col gap-1">
        <div className="flex flex-row items-center justify-between gap-1">
          <div className="relative flex items-center">
            <span className="grow font-bold">{name}</span>

            {!isUser && !embed && (
              <div className="absolute -right-6">
                <FavouriteButton replicaSlug={replicaSlug} isFavourite={isFavourite} setIsFavourite={setIsFavourite} />
              </div>
            )}
          </div>

          <div className="flex items-end gap-x-2">
            {/* {context && (
              <PrimaryButton className="transform scale-75" onClick={() => setShowContext(!showContext)} type="button">
                {showContext ? 'Hide Context' : 'Show Context'}
              </PrimaryButton>
            )} */}
            <span className="shrink-0 text-xs opacity-50">{createdAt && <ChatDate createdAt={createdAt} />}</span>
          </div>
        </div>
        {/* {showContext && <div className="bg-gray-100 p-4 rounded-md shadow-md whitespace-pre-wrap">{context}</div>} */}

        <MemoizedReactMarkdown
          className="prose dark:prose-invert prose-p:leading-relaxed prose-pre:p-0 break-words"
          remarkPlugins={remarkPlugins}
          components={{
            h3({ children }) {
              return <h3 className="pb-2 pt-4 text-2xl font-normal">{children}</h3>
            },
            p({ children }) {
              return <p className="mb-1 max-w-full whitespace-break-spaces last:mb-0">{children}</p>
            },
            ul({ children }) {
              return <ul className="my-0">{children}</ul>
            },
            ol({ children }) {
              return <ol className="my-0">{children}</ol>
            },
            a({ children, ...props }) {
              return (
                <a
                  href={props.href}
                  target={'_blank'}
                  className="font-bold text-primary underline underline-offset-2 transition-opacity duration-300 hover:opacity-70"
                  rel="noreferrer"
                >
                  {children}
                </a>
              )
            },
            code({ /*node,*/ inline, className, children, ...props }) {
              if (children.length) {
                if (children[0] === '▍') {
                  return <span className="mt-1 animate-pulse cursor-default">▍</span>
                }

                children[0] = (children[0] as string).replace('`▍`', '▍')
              }

              const match = /language-(\w+)/.exec(className || '')

              if (inline) {
                return (
                  <code className={className} {...props}>
                    {children}
                  </code>
                )
              }

              return (
                <CodeBlock
                  key={Math.random()}
                  language={match?.[1] || ''}
                  value={String(children).replace(/\n$/, '')}
                  {...props}
                />
              )
            },
          }}
        >
          {content}
        </MemoizedReactMarkdown>

        <div className="flex gap-2">
          {sources
            .filter((source) => source.score > 0)
            .map((source, index) => (
              <ChunkReferenceLink key={index + 1} index={index + 1} source={source} />
            ))}
        </div>

        {role !== 'assistant' && <ChatImages images={images || []} />}

        {role === 'assistant' && !embed && (
          <MessageButtons
            name={name}
            replicaSlug={replicaSlug}
            content={content}
            isReplicaTyping={isReplicaTyping}
            voiceEnabled={voiceEnabled}
            replicaUuid={replicaUuid}
          />
        )}
      </div>
    </div>
  )
})
