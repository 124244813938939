
import { callServer } from 'next/dist/client/app-call-server'

function __build_action__(action, args) {
  return callServer(action.$$id, args)
}

/* __next_internal_action_entry_do_not_use__ {"3f1a5bf1a2b79d3419767e024cdcfc4a86bae899":"getLastChatHistoryMessageSources","b3d71dd8e9682a758239eb5fbbdeb0c352b0cde1":"$$ACTION_0"} */ export var getLastChatHistoryMessageSources = createServerReference("3f1a5bf1a2b79d3419767e024cdcfc4a86bae899");
import { createServerReference } from "private-next-rsc-action-client-wrapper";

