'use client'

import ChatError from '@/app/[replicaSlug]/ChatError'
import useChatReplicaError from '@/app/[replicaSlug]/useChatReplicaError'
import type { GetV1ReplicasByReplicaUuidChatHistoryResponse } from '@/app/client/types.gen'
import { getHigherPlan } from '@/app/pricing/[[...slugs]]/stripe-plans'
import StudioWarning from '@/app/studio/StudioWarning'
import { isVoiceEnabled } from '@/app/studio/[replicaSlug]/voice/is-voice-enabled'
import { getMessageHistoryCountQueryKey } from '@/app/studio/[replicaSlug]/voice/query-keys'
import { Alert } from '@/components/Alert'
import LoadingState from '@/components/LoadingState'
import { getQueryClient } from '@/components/ReactQueryClientProvider'
import type { getAllChatHistoryMessages } from '@/db/models/chat_history'
import { userExceedChatLimit } from '@/lib/plan'
import { trackEvent } from '@/lib/trackEvent'
import type { Replica, User } from '@/lib/types/supabase'
import { getLastChatHistoryMessageSources } from '@/server-actions/chat'
import { getUserReplicaMessagesForPastDay } from '@/server-actions/replicas'
import { getIsReplicaInteractiveAvatarEnabledServerAction } from '@/server-actions/video'
import { wrapServerActionCall } from '@/server-actions/wrapServerAction'
import { useQuery } from '@tanstack/react-query'
import type { Message } from 'ai'
import { useChat } from 'ai/react'
import type { Session } from 'next-auth'
import { signIn } from 'next-auth/react'
import { useCallback, useEffect, useId, useMemo, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import ChatHistory from './ChatHistory'
import ChatInput from './ChatInput'
import type { Source } from './ChatMessage'
import { ChatSuggestions } from './ChatSuggestions'
import ReplicaTypingIndicator from './ReplicaTypingIndicator'
import UpgradePlanAlert from './UpgradePlanAlert'
import useChatScrollAnchor from './useScrollAnchor'

export type HistoryMessage = {
  id: string
  name: string
  role: Message['role']
  profileUrl: string
  content: string
  context?: string
  voiceEnabled: boolean
  createdAt: Date
  images?: string[]
  sources: Source[]
}

type History = Awaited<ReturnType<typeof getAllChatHistoryMessages>>

export interface ChatProps {
  favourited: boolean
  session: Session | null
  v?: string
  user?: User | null
  replica: Replica
  inputPrompt?: string
  userName?: string | null
  messageCap: boolean
  history?: GetV1ReplicasByReplicaUuidChatHistoryResponse['items'] | History
  ignoreSubscriptions?: boolean
  embed?: boolean
  className?: string
  classNameInput?: string
  primaryBgColor?: string
  skipAuth?: boolean
}

function structureImageMessages(messageImages: (string | Blob | MediaSource)[], fromSupabase: boolean) {
  const imageMessages: string[] = []

  messageImages.map((image: string | Blob | MediaSource) => {
    imageMessages.push(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      image.length > 40
        ? image
        : fromSupabase
          ? `${process.env.NEXT_PUBLIC_STORAGE_PATH}/athena_images/${image}`
          : URL.createObjectURL(image as Blob | MediaSource),
    )
  })

  return imageMessages
}

const Chat = ({
  favourited,
  session,
  user,
  replica,
  inputPrompt,
  userName,
  messageCap,
  history = [],
  embed = false,
  className = '',
  classNameInput = '',
  primaryBgColor,
  skipAuth = false,
}: ChatProps) => {
  const { error, setError, resetError } = useChatReplicaError()
  const username = user ? (user.name ? user.name : (user.email ?? '')) : (userName ?? 'Anonymous')

  const replicaVoiceEnabled = isVoiceEnabled(replica.voice_manager, replica.elevenlabsId, replica.el_custom_voice_id)

  const defaultChatHistory = useMemo(
    () =>
      history.map((message) => {
        const name = message.role === 'assistant' ? replica.name : username
        let profileUrl = ''

        if (message.role === 'assistant') {
          profileUrl = replica.profile_image
        } else if (user?.profile_image) {
          profileUrl = user.profile_image
        }

        const elevenlabsId = message.role === 'assistant' ? replica.elevenlabsId : ''
        const voiceManager = message.role === 'assistant' ? replica.voice_manager : 'none'
        const userElevenlabsId = message.role === 'assistant' ? replica.el_custom_voice_id : ''

        if (message.images) {
          const imageMessages = structureImageMessages(message.images, true)

          message.images = imageMessages
        }

        return {
          id: String(message.id),
          name,
          role: message.role,
          profileUrl,
          content: message.content,
          elevenlabsId,
          voiceManager,
          userElevenlabsId,
          createdAt: new Date(message.created_at),
          images: undefined,
          sources: message.sources || [],
          voiceEnabled: message.role === 'assistant' && replicaVoiceEnabled,
        } as HistoryMessage
      }),
    [
      history,
      replica.name,
      replica.elevenlabsId,
      replica.voice_manager,
      replica.el_custom_voice_id,
      replica.slug,
      replica.profile_image,
      username,
      user?.profile_image,
      replicaVoiceEnabled,
    ],
  )

  const chatHistoryRef = useRef<HistoryMessage[]>(defaultChatHistory)

  const getRandomSuggestionQuestions = useCallback(() => {
    if (!replica.suggested_questions || !Array.isArray(replica.suggested_questions)) return []

    const shuffledQuestions = [...replica.suggested_questions].sort(() => Math.random() - 0.5)
    const firstThreeQuestions = shuffledQuestions.slice(0, 3)
    return firstThreeQuestions
  }, [replica.suggested_questions])

  const randomizeSuggestionQuestions = useCallback(() => {
    setSuggestedQuestions(getRandomSuggestionQuestions())
  }, [getRandomSuggestionQuestions])

  const [suggestedQuestions, setSuggestedQuestions] = useState<string[]>([])
  const [isClearingChat, setIsClearingChat] = useState<boolean>(false)
  const [latestImages, setLatestImages] = useState<string[]>()
  const initialMessages = useMemo(() => {
    return history.map((message) => ({
      id: String(message.id),
      content: message.content,
      role: message.role,
      sources: message.sources || [],
      createdAt: new Date(message.created_at),
    }))
  }, [history]) as Message[]

  const now = new Date()
  const localTimeString = new Date(now.getTime() - now.getTimezoneOffset() * 60000).toISOString()
  const {
    data,
    input,
    setInput,
    messages,
    isLoading: isReplicaTyping,
    stop,
    handleSubmit,
    handleInputChange,
    append,
    setMessages,
  } = useChat({
    initialMessages,
    body: {
      date: localTimeString,
    },
    onFinish() {
      ;(async () => {
        const sources = await wrapServerActionCall(getLastChatHistoryMessageSources)(replica.uuid)
        const updatedChatHistory = chatHistoryRef.current.map((msg, index) => {
          if (index === chatHistoryRef.current.length - 1) {
            return {
              ...msg,
              sources,
            }
          }
          return msg
        })
        chatHistoryRef.current = updatedChatHistory
        setMessages([{ id: 'update', content: '', role: 'assistant' }]) //Hack to trigger an update
        setLatestImages(undefined)
      })()
      const queryClient = getQueryClient()
      queryClient.setQueryData(getMessageHistoryCountQueryKey(replica.uuid), messageHistoryLengthPastDay + 1)
      setLatestImages(undefined)
    },
    onError(error) {
      setError({
        // TODO: This should be handled
        friendlyError: 'Uh oh, something went wrong!',
        error,
      })
    },
    onResponse(response) {
      setDisplayLimitAlert(response.status === 403)
    },
    api: `/api/chat/${replica.slug}/web`,
  })

  const chatHistory = useMemo(() => {
    const _chatHistory = [...chatHistoryRef.current] as HistoryMessage[]
    if (messages.length === 0) return _chatHistory

    const lastMessage = messages[messages.length - 1] as Message & {
      sources?: Source[]
      context?: string
    }

    const name = lastMessage.role === 'assistant' ? replica.name : username
    let profileUrl = ''

    if (lastMessage.role === 'assistant') {
      profileUrl = replica.profile_image!
    } else if (user) {
      profileUrl = user.profile_image || ''
    }

    const messageToHistory: HistoryMessage = {
      id: lastMessage.id,
      name,
      role: lastMessage.role,
      profileUrl: profileUrl,
      voiceEnabled: lastMessage.role === 'assistant' && replicaVoiceEnabled,
      content: lastMessage.content,
      context: lastMessage.context,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      createdAt: new Date(lastMessage.createdAt),
      images: latestImages || [],
      sources: lastMessage.sources || [],
    }

    if (_chatHistory.length === 0 || String(_chatHistory[_chatHistory.length - 1].id) !== String(messageToHistory.id)) {
      if (!_chatHistory.some((chat) => String(chat.id) === String(messageToHistory.id))) {
        // this prevents exisiting messages which were returned before an error being instered into the chat history for a second time if the last message doesn't doesn't update after a failed api response
        _chatHistory.push(messageToHistory)
      }
    } else {
      _chatHistory[_chatHistory.length - 1] = messageToHistory
    }

    chatHistoryRef.current = _chatHistory
    return _chatHistory
  }, [
    messages,
    data,
    user,
    username,
    replica.name,
    replica.elevenlabsId,
    replica.voice_manager,
    replica.el_custom_voice_id,
    replica.profile_image,
    replica.slug,
    latestImages,
  ])

  const { data: messageHistoryLengthPastDay = 0 } = useQuery({
    queryKey: getMessageHistoryCountQueryKey(replica.uuid),
    queryFn: async () => await wrapServerActionCall(getUserReplicaMessagesForPastDay)(),
  })
  const requiredPlan = session ? getHigherPlan(session.customer.plan)! : 'starter'
  const userPlan = session ? session.customer.plan : null
  const messageLimitReached = useMemo(
    () => messageCap && userExceedChatLimit(userPlan ? messageHistoryLengthPastDay : chatHistory.length, userPlan),
    [messageHistoryLengthPastDay, messageCap, userPlan, chatHistory.length],
  )
  const [displayLimitAlert, setDisplayLimitAlert] = useState<boolean>(messageLimitReached)
  const lastMessageRole = chatHistory[chatHistory.length - 1]?.role || 'assistant'

  const id = useId()
  const isNewChat = chatHistory.length === 0

  const hasSuggestedQuestions = !!replica.suggested_questions?.length
  const isAuthenticated = !!session

  const sendSuggestion = useCallback(
    async (content: string) => append({ id: id, content: content, role: 'user', createdAt: new Date() }),
    [append, id],
  )

  const clearChat = async () => {
    if (!history) return

    try {
      setIsClearingChat(true)

      if (user) {
        const response = await fetch('/api/chat/clear', {
          method: 'POST',
          body: JSON.stringify({
            replicaSlug: replica.slug,
          }),
        })

        const res = await response.json()

        if (!response.ok) {
          throw new Error(res.message)
        }
      }

      setLatestImages(undefined)
      chatHistoryRef.current = []
      setMessages([])
      resetError()
    } catch (error) {
      setError({
        error,
        friendlyError: 'Something went wrong clearing chat.',
      })
    } finally {
      setIsClearingChat(false)
    }
  }

  useEffect(() => {
    randomizeSuggestionQuestions()
  }, [randomizeSuggestionQuestions])

  const { data: interactiveAvatarEnabled = false } = useQuery({
    queryKey: ['interactive-avatar-enabled', replica.slug],
    queryFn: () => wrapServerActionCall(getIsReplicaInteractiveAvatarEnabledServerAction)(replica.slug),
  })

  const { scrollLine, scrollToBottom } = useChatScrollAnchor({
    trackVisibility: isReplicaTyping,
    lastMessage: chatHistory[chatHistory.length - 1]?.content,
    hasMessages: !isNewChat,
  })

  useEffect(() => {
    if (lastMessageRole === 'user') scrollToBottom()
  }, [lastMessageRole])

  return (
    <div>
      <div className="flex flex-col gap-3 pb-[90px] pt-4 md:pt-10 relative">
        {hasSuggestedQuestions && (
          <ChatSuggestions
            className="mt-6"
            isNewChat={isNewChat}
            suggestedQuestions={suggestedQuestions}
            getSuggestionQuestions={randomizeSuggestionQuestions}
            setSuggestedQuestion={sendSuggestion}
            replica={replica.slug}
          />
        )}

        {isClearingChat && (
          <LoadingState
            text="Deleting..."
            className="fixed top-1/2 left-1/2 z-40 px-6 py-1 rounded-sm border backdrop-blur-md backdrop-saturate-200 transform -translate-x-1/2 -translate-y-1/2 w-fit border-black/5 bg-background/80 text-gray-800/60"
          />
        )}

        {!isAuthenticated && messages.length > 0 && !error && !skipAuth && (
          <Alert
            heading="Save Your Chat History"
            type="info"
            actionBtn={{
              text: 'Continue',
              handleActionBtn: () => signIn(),
            }}
          >
            <div className="flex flex-row justify-between items-center w-full">
              <p>Sign in or create an account to preserve your chat history for future access.</p>
            </div>
          </Alert>
        )}

        <ChatHistory
          favourited={favourited}
          messages={chatHistory}
          isReplicaTyping={isReplicaTyping}
          replicaSlug={replica.slug}
          model={replica.model}
          embed={embed}
          replicaUuid={replica.uuid}
        />

        {(messageLimitReached || displayLimitAlert) && <UpgradePlanAlert requiredPlan={requiredPlan} />}

        <ChatError />
        <StudioWarning />

        {scrollLine}
      </div>

      <div
        className={twMerge(
          'fixed inset-x-0 bottom-[-1px] z-20 mx-auto w-full max-w-4xl bg-background/80 px-3 pb-8',
          className,
        )}
      >
        <ReplicaTypingIndicator name={replica.name} isTyping={isReplicaTyping} />

        <ChatInput
          interactiveAvatarEnabled={interactiveAvatarEnabled}
          interactiveAvatarId={replica.hg_custom_avatar_id}
          inputPrompt={inputPrompt}
          input={input}
          setInput={setInput}
          handleInputChange={handleInputChange}
          handleSubmit={(e, data, inputImage) => {
            trackEvent('MESSAGE_SENT', { avatarId: replica.slug })
            if (inputImage) {
              setLatestImages(structureImageMessages(inputImage, false))
            }
            resetError()
            handleSubmit(e, data)
          }}
          isReplicaTyping={isReplicaTyping}
          stop={stop}
          replicaName={replica.name}
          clearChat={clearChat}
          isNewChat={isNewChat}
          appendMessage={append}
          replicaSlug={replica.slug}
          embed={embed}
          classNameInput={classNameInput}
          primaryBgColor={primaryBgColor}
          isDisabled={displayLimitAlert}
        />
      </div>
    </div>
  )
}

export default Chat
