'use client'
import StreamingAvatar, {
  AvatarQuality,
  type StartAvatarResponse,
  StreamingEvents,
  TaskType,
} from '@heygen/streaming-avatar'
import { usePrevious } from 'ahooks'
import { useEffect, useRef, useState } from 'react'
import useReplicaChat from './use-replica-chat'

export default function useHeygenAvatar({
  avatarId,
  replicaSlug,
  onError,
  setIsResponding,
}: {
  avatarId: string
  replicaSlug: string
  onError?: (message: string, error: Error) => void
  setIsResponding: (isResponding: boolean) => void
}) {
  const avatar = useRef<StreamingAvatar | null>(null)
  const [sessionRunning, setSessionRunning] = useState<boolean>(false)
  const [isLoadingSession, setIsLoadingSession] = useState<boolean>(false)
  const [stream, setStream] = useState<MediaStream>()
  const [avatarSessionData, setAvatarSessionData] = useState<StartAvatarResponse>()
  const [_, setIsSpeaking] = useState<boolean>(false)
  const [lastHeygenSpeechTaskId, setLastHeygenSpeechTaskId] = useState<string | null>(null)

  const {
    input,
    handleSubmit: _handleSubmit,
    handleInputChange,
    append,
  } = useReplicaChat({
    onNewSentence: async (text, isLast) => {
      if (!avatar.current) return

      try {
        setIsSpeaking(true)
        const res: { task_id: string } = await avatar.current.speak({ text: text, task_type: TaskType.REPEAT })
        if (isLast) setLastHeygenSpeechTaskId(res.task_id)
      } catch (error) {
        onError?.('An unknown error occurred.', error as Error)
        setIsSpeaking(false)
      }
    },
    replicaSlug,
  })

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    _handleSubmit(e)
    setIsResponding(true)
  }

  useEffect(() => {
    if (!avatar.current) return

    function onAvatarStartTalking() {
      setIsSpeaking(true)
    }

    function onAvatarStopTalking(e: { detail: { task_id: string } }) {
      setIsSpeaking(false)
      if (lastHeygenSpeechTaskId === e.detail.task_id) {
        setLastHeygenSpeechTaskId(null)
        setIsResponding(false)
      }
    }

    function onAvatarStreamReady(e: { detail: MediaStream }) {
      setStream(e.detail)
      setSessionRunning(true)
      setIsLoadingSession(false)
    }

    function onStreamDisconnected() {
      setSessionRunning(false)
      endSession()
    }

    avatar.current.on(StreamingEvents.AVATAR_START_TALKING, onAvatarStartTalking)
    avatar.current.on(StreamingEvents.AVATAR_STOP_TALKING, onAvatarStopTalking)
    avatar.current.on(StreamingEvents.STREAM_DISCONNECTED, onStreamDisconnected)
    avatar.current.on(StreamingEvents.STREAM_READY, onAvatarStreamReady)

    return () => {
      avatar.current?.off(StreamingEvents.AVATAR_START_TALKING, onAvatarStartTalking)
      avatar.current?.off(StreamingEvents.AVATAR_STOP_TALKING, onAvatarStopTalking)
      avatar.current?.off(StreamingEvents.STREAM_DISCONNECTED, onStreamDisconnected)
      avatar.current?.off(StreamingEvents.STREAM_READY, onAvatarStreamReady)
    }
  }, [avatar.current, lastHeygenSpeechTaskId, setIsResponding])

  async function startSession() {
    setIsLoadingSession(true)

    try {
      const newToken = await fetchAccessToken(replicaSlug)
      avatar.current = new StreamingAvatar({
        token: newToken,
      })

      const res = await avatar.current.createStartAvatar({
        quality: AvatarQuality.High,
        avatarName: avatarId,
      })

      setAvatarSessionData(res)
      // biome-ignore lint/suspicious/noExplicitAny: There's no Error type from HeyGen
    } catch (error: any) {
      try {
        const response = typeof error.responseText === 'string' ? JSON.parse(error.responseText) : error.responseText
        const errorCode = response.code
        let message = response.message

        if (errorCode === 10013) message = 'Invalid Avatar ID. Please verify and re-enter your HeyGen Avatar ID.'
        if (errorCode === 400112) message = 'Invalid API Key. Please check your HeyGen API Key and try again.'
        if (errorCode === 10015) message = "You've run out of HeyGen credits. Please top up your account to continue."

        onError?.(`HeyGen: ${message}`, error as Error)
      } catch (error) {
        onError?.('Something went wrong. Please try again or contact support with', error as Error)
      }
    } finally {
      setStream(undefined)
      setIsLoadingSession(false)
    }
  }

  const endSession = async () => {
    if (!avatarSessionData) return

    if (!avatar.current) {
      onError?.('Avatar API not initialized', new Error('Avatar API not initialized'))
      return
    }

    await avatar.current.stopAvatar()
    setStream(undefined)
  }

  const previousText = usePrevious(input)
  useEffect(() => {
    if (!avatarSessionData) return

    if (!previousText && input) {
      avatar.current?.startListening()
    } else if (previousText && !input) {
      avatar?.current?.stopListening()
    }
  }, [input, previousText, avatarSessionData])

  return {
    isLoadingSession,
    startSession,
    endSession,
    sessionRunning,
    handleSubmit,
    input,
    handleInputChange,
    append,
    stream,
  }
}

async function fetchAccessToken(replicaSlug: string) {
  const response = await fetch(`/api/heygen/access-token?replicaSlug=${replicaSlug}`, {
    method: 'POST',
  })
  const res = await response.json()

  return res.token
}
