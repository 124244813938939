'use client'
import ChatError from '@/app/[replicaSlug]/ChatError'
import useChatReplicaError from '@/app/[replicaSlug]/useChatReplicaError'
import Spinner from '@/components/Spinner'
import { CaretRight, Play, X } from '@phosphor-icons/react'
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import Dictation from './dictation'
import useHeygenAvatar from './use-heygen-avatar'

const VideoStreaming = forwardRef(function VideoStreaming(
  {
    className,
    avatarId,
    replicaSlug,
    autoplay = false,
    silenceDuration = 1500,
  }: {
    className?: string
    autoplay?: boolean
    silenceDuration?: number
    avatarId: string
    replicaSlug: string
  },
  ref,
) {
  const mediaStream = useRef<HTMLVideoElement>(null)

  const { error, resetError, setError } = useChatReplicaError()

  const [isPlaying, setIsPlaying] = useState<boolean>(false)
  const [muted, setMuted] = useState<boolean>(true)
  const inputRef = useRef<HTMLInputElement>(null)
  const [isResponding, setIsResponding] = useState<boolean>(false)

  const {
    stream,
    startSession,
    endSession,
    append,
    sessionRunning,
    isLoadingSession,
    input,
    handleSubmit,
    handleInputChange,
  } = useHeygenAvatar({
    avatarId,
    replicaSlug,
    onError: (message, error) => setError({ error, friendlyError: message }),
    setIsResponding,
  })

  const hidePlayer = !autoplay && !sessionRunning && !isLoadingSession

  useImperativeHandle(ref, () => ({
    streamHandler,
  }))

  function streamHandler() {
    if (stream) endSession()
    else startSession()
  }

  useEffect(() => {
    if (stream && mediaStream.current) {
      mediaStream.current.srcObject = stream

      const handlePlay = () => setIsPlaying(true)
      const handlePause = () => setIsPlaying(false)

      mediaStream.current.addEventListener('play', handlePlay)
      mediaStream.current.addEventListener('pause', handlePause)

      // Cleanup function to remove event listeners
      return () => {
        if (mediaStream.current) {
          mediaStream.current.removeEventListener('play', handlePlay)
          mediaStream.current.removeEventListener('pause', handlePause)
          mediaStream.current.onloadedmetadata = null
        }

        // endSession()
      }
    }
  }, [stream])

  useEffect(() => {
    if (!autoplay) return

    startSession()
  }, [autoplay])

  if (hidePlayer) return <></>

  const isLoading = isLoadingSession || !sessionRunning

  return (
    <div
      className={twMerge(
        'relative group h-[420px] w-[98%] mx-auto overflow-hidden rounded-2xl md:h-[500px] md:w-full md:bottom-10 z-10 bg-black',
        !isPlaying && 'bg-black',
        className,
      )}
    >
      {!isPlaying && stream && (
        <div
          onClick={() => {
            mediaStream.current?.play()
            inputRef.current?.focus()
            setMuted(false)
          }}
          className="group cursor-pointer absolute inset-0 bg-black/50 z-20 text-white grid place-content-center"
        >
          <span>
            <Play className="group-hover:scale-110 transition-all" size={30} />
          </span>
        </div>
      )}

      {stream && !autoplay && (
        <button
          className="absolute right-2 top-2 z-20 rounded-full bg-white/30 p-1 text-sm text-black opacity-30 transition-all duration-300 group-hover:opacity-100"
          onClick={endSession}
        >
          <X size={20} />
        </button>
      )}

      <div className="absolute left-0 right-0 top-0 bg-background z-20">
        <ChatError />
      </div>

      <form
        onSubmit={(e) => {
          handleSubmit(e)
          setIsResponding(true)
          if (error) resetError()
        }}
        className={twMerge(
          'absolute inset-x-0 bottom-0 pb-4 pt-2 px-4 mx-auto bg-transparent backdrop-blur z-10',
          !stream && 'opacity-50',
        )}
      >
        <fieldset className="rounded flex items-center bg-white p-1" disabled={!stream}>
          <input
            ref={inputRef}
            autoFocus
            className="px-3 h-auto w-full resize-none overflow-y-auto border-0 bg-transparent text-black placeholder:text-slate-350 focus-within:outline-none focus:ring-0"
            value={input}
            onChange={handleInputChange}
            placeholder="Type something..."
            disabled={!stream}
          />

          <Dictation
            append={append}
            listeningPaused={isResponding}
            silenceDuration={silenceDuration}
            disabled={!stream}
            onSubmitted={() => setIsResponding(true)}
          />

          <button
            className="flex h-[42px] w-[42px] shrink-0 items-center justify-center rounded-full bg-primary text-white transition-all duration-300 hover:opacity-70"
            type="submit"
          >
            <CaretRight weight="bold" width={16} height={16} className="fill-current" />
          </button>
        </fieldset>
        <div className="flex items-center text-xs text-white">
          Press Mic and leave it open to enable speech-to-speech.
        </div>
      </form>

      {stream && (
        <video
          ref={mediaStream}
          autoPlay={false}
          muted={muted}
          playsInline
          width={240}
          height={320}
          style={{
            objectFit: 'cover',
          }}
          className="h-full w-full object-cover"
        />
      )}

      {isLoading && (
        <div className="flex h-full w-full items-center justify-center text-white bg-black">
          <Spinner size={40} />
        </div>
      )}
    </div>
  )
})

export default VideoStreaming
