'use client'
import useWhisperTranscription from '@/hooks/useWhisperTranscription'
import { ArrowRight, CircleNotch, Microphone, Stop, Trash, VideoCamera } from '@phosphor-icons/react'
import type { ChatRequestOptions, CreateMessage, Message } from 'ai'
import { memo } from 'react'
import {
  type ChangeEventHandler,
  type FormEvent,
  type KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import ShortUniqueId from 'short-unique-id'
import { twMerge } from 'tailwind-merge'
import VideoStreaming from './video-streaming/VideoStreaming'

export interface ChatInputProps {
  input: string
  setInput: (input: string) => void
  handleInputChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  handleSubmit: (
    e: FormEvent<HTMLFormElement>,
    data: Record<string, unknown> | undefined,
    inputImages: (File | string)[] | undefined,
  ) => void
  isDisabled?: boolean
  replicaName: string
  isReplicaTyping: boolean
  inputPrompt?: string
  stop: () => void
  isNewChat: boolean
  clearChat: () => void
  appendMessage: (
    message: Message | CreateMessage,
    chatRequestOptions?: ChatRequestOptions,
  ) => Promise<string | null | undefined> | null
  replicaSlug: string
  embed?: boolean
  interactiveAvatarId: string | null
  interactiveAvatarEnabled: boolean
  classNameInput?: string
  primaryBgColor?: string
}

export default memo(function ChatInput({
  input,
  handleInputChange,
  handleSubmit,
  isDisabled = false,
  replicaName,
  isReplicaTyping,
  inputPrompt = 'Message',
  stop,
  isNewChat,
  clearChat,
  appendMessage,
  replicaSlug,
  embed = false,
  interactiveAvatarId,
  interactiveAvatarEnabled,
  classNameInput = '',
  primaryBgColor = 'bg-primary',
}: ChatInputProps) {
  const { isListening, formatDuration, duration, isProcessing, toggleRecording } = useWhisperTranscription(
    (input: string) => {
      const { randomUUID } = new ShortUniqueId({ length: 10 })
      const uuid = randomUUID()
      appendMessage({
        id: uuid,
        content: input,
        role: 'user',
        createdAt: new Date(),
      })
    },
    isReplicaTyping,
  )

  const videoStreamingRef = useRef<{
    streamHandler: () => void
  }>(null)
  const [uploadImageModal, setUploadImageModal] = useState<boolean>(false)

  const textareaRef = useRef<HTMLTextAreaElement | null>(null)

  const handleTextareaInput = () => {
    const currentTextareaRef = textareaRef.current

    if (!currentTextareaRef) return

    const scrollHeight = currentTextareaRef.scrollHeight

    if (scrollHeight > 60) {
      currentTextareaRef.style.overflowY = 'scroll'
      currentTextareaRef.style.height = '60px'
    } else if (scrollHeight > 45) {
      currentTextareaRef.style.overflowY = 'hidden'
      currentTextareaRef.style.height = '45px'
    } else {
      currentTextareaRef.style.overflowY = ''
      currentTextareaRef.style.height = ''
    }
  }

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (sendMessageDisabled) {
      return
    }
    handleSubmit(e, undefined, undefined)

    const currentTextAreaRef = textareaRef.current

    if (!currentTextAreaRef) return

    currentTextAreaRef.style.overflowY = ''
    currentTextAreaRef.style.height = ''
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLFormElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      setUploadImageModal(false)
      onSubmit(e)
    }
  }

  const focusTextarea = useCallback(() => {
    if (textareaRef.current) {
      textareaRef.current.focus()
    }
  }, [])

  useEffect(() => {
    if (!isDisabled && !isReplicaTyping) focusTextarea()
  }, [isDisabled, isReplicaTyping, focusTextarea])

  const hasText = !!input.trim().length

  const sendMessageDisabled = isDisabled || !hasText

  let textareaPlaceholder = ''
  if (isListening) textareaPlaceholder = 'Listening...'
  else if (isProcessing) textareaPlaceholder = 'Processing...'
  else textareaPlaceholder = `${inputPrompt} ${replicaName}...`

  return (
    <div className="flex w-full flex-col gap-1.5">
      {interactiveAvatarId && interactiveAvatarEnabled ? (
        <VideoStreaming
          className="absolute inset-x-0 bottom-8"
          replicaSlug={replicaSlug}
          ref={videoStreamingRef}
          avatarId={interactiveAvatarId}
        />
      ) : null}

      <form onSubmit={onSubmit} onKeyDown={handleKeyDown}>
        <fieldset
          className="flex w-full flex-row items-center justify-center gap-2"
          disabled={isDisabled || isReplicaTyping}
        >
          {!isNewChat && !embed && (
            <button
              className={twMerge(
                'flex h-[42px] w-[42px] shrink-0 items-center justify-center rounded-full bg-gray-400 text-white shadow-md transition-all duration-300',
                isReplicaTyping || isDisabled ? 'opacity-40 pointer-events-none' : 'hover:opacity-80',
              )}
              type="button"
              disabled={isReplicaTyping}
              onClick={() => {
                clearChat()
              }}
            >
              <Trash className="h-6 w-6 fill-current " />
            </button>
          )}

          <div
            className={twMerge(
              'flex min-h-[42px] w-full items-center rounded-3xl border border-[#dfd9c8]/50 bg-[#ece6d6] pl-4 pr-1 shadow-md',
              classNameInput,
            )}
          >
            <textarea
              ref={textareaRef}
              className={twMerge(
                'h-auto w-full resize-none overflow-y-auto border-0 bg-transparent p-0 text-black placeholder:text-[#969285] focus-within:outline-none focus:ring-0',
                (isDisabled || isReplicaTyping) && 'opacity-40',
              )}
              value={uploadImageModal ? '' : input}
              onChange={handleInputChange}
              onInput={handleTextareaInput}
              placeholder={textareaPlaceholder}
              rows={1}
              disabled={isDisabled || isReplicaTyping || isListening || isProcessing}
            />

            <div className="flex items-center">
              {interactiveAvatarId && interactiveAvatarEnabled && (
                <button
                  className={twMerge(
                    'flex items-center justify-center w-10 border-l border-[#dfd9c8]/90 py-2.5',
                    (isDisabled || isReplicaTyping) && 'opacity-40',
                  )}
                  onClick={videoStreamingRef.current?.streamHandler}
                  type="button"
                >
                  <VideoCamera className="h-5 w-5 text-gray-500" />
                </button>
              )}

              <button
                className={twMerge(
                  'flex items-center justify-center w-10 border-l border-[#dfd9c8]/90 py-2.5',
                  (isDisabled || isReplicaTyping) && 'opacity-40',
                  isListening && 'w-24',
                )}
                onClick={toggleRecording}
                type="button"
                disabled={isProcessing}
              >
                {!isProcessing &&
                  (isListening ? (
                    <Stop className="flex-shrink-0 h-5 w-5 text-red-500" />
                  ) : (
                    <Microphone className="h-5 w-5 text-gray-500" />
                  ))}

                {isProcessing && <CircleNotch className="h-5 w-5 text-gray-500 animate-spin" />}

                {isListening && <span className="ml-2 text-sm text-red-500"> {formatDuration(duration)}</span>}
              </button>
            </div>
          </div>

          {isReplicaTyping && (
            <button
              type="button"
              onClick={stop}
              className="flex h-[42px] w-[42px] shrink-0 items-center justify-center rounded-full bg-[#ace16b] text-white shadow-md transition-all duration-300 hover:opacity-70"
            >
              <Stop className="h-[16px] w-[16px]" weight="fill" />
            </button>
          )}

          {!isReplicaTyping && (
            <button
              disabled={sendMessageDisabled}
              className={twMerge(
                'flex h-[42px] w-[42px] shrink-0 items-center justify-center rounded-full text-white shadow-md transition-all duration-300 hover:opacity-70',
                primaryBgColor,
                sendMessageDisabled && 'opacity-20 pointer-events-none',
              )}
              type="submit"
            >
              <ArrowRight
                weight="bold"
                width={20}
                height={20}
                className={twMerge('fill-current transition-transform duration-300', hasText && 'rotate-[-90deg]')}
              />
            </button>
          )}
        </fieldset>
      </form>
      <div
        className={twMerge(
          'hidden text-xs opacity-0 transition-opacity duration-300 sm:inline',
          hasText && 'opacity-50',
          isNewChat ? 'pl-6' : 'pl-[70px]',
        )}
      >
        Press <span className="font-bold">Enter</span> to send the message. Press{' '}
        <span className="font-bold">Shift + Enter</span> to start a new line.
      </div>
    </div>
  )
})
