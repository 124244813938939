'use client'

import useChatReplicaError from '@/app/[replicaSlug]/useChatReplicaError'
import { Heart } from '@phosphor-icons/react'
import { signIn, useSession } from 'next-auth/react'

const FavouriteButton = ({
  replicaSlug,
  isFavourite,
  setIsFavourite,
}: {
  replicaSlug: string
  isFavourite: boolean | undefined
  setIsFavourite: (isFavourite: boolean) => void
}) => {
  const { setError } = useChatReplicaError()
  const { status } = useSession()

  const saveFavourite = async () => {
    try {
      setIsFavourite(true)

      await fetch(`/api/user/favourite/${replicaSlug}`, {
        method: 'POST',
      })
    } catch (error) {
      setError({
        error,
        friendlyError: 'Something went wrong favouriting replica.',
      })
      setIsFavourite(false)
    }
  }

  const removeFavourite = async () => {
    try {
      setIsFavourite(false)

      await fetch(`/api/user/favourite/${replicaSlug}`, {
        method: 'DELETE',
      })
    } catch (error) {
      setError({
        error,
        friendlyError: 'Something went wrong removing favourite from replica.',
      })
      setIsFavourite(true)
    }
  }

  const onClickFavourite = () => {
    if (status !== 'authenticated') return signIn()

    if (isFavourite) removeFavourite()
    else saveFavourite()
  }

  return (
    <button
      className="ml-1.5 flex h-[30px] cursor-pointer flex-row items-center justify-center"
      onClick={onClickFavourite}
    >
      <Heart size={16} fill={isFavourite ? 'red' : undefined} weight={isFavourite ? 'fill' : 'regular'} />
    </button>
  )
}

export default FavouriteButton
