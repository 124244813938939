import Link from 'next/link'
import type { MouseEventHandler } from 'react'
import { twMerge } from 'tailwind-merge'

type LinkButtonProps = {
  href?: string
  onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement> | undefined
  children: React.ReactNode
  className?: string
  leftIcon?: React.ReactNode
  rightIcon?: React.ReactNode
  bordered?: boolean
  primary?: boolean
  disabled?: boolean
  target?: string
  type?: 'button' | 'submit' | 'reset'
}

const LinkButton = ({
  href,
  onClick,
  children,
  className,
  leftIcon,
  rightIcon,
  bordered = false,
  primary = false,
  disabled = false,
  target = '',
  type = 'button',
}: LinkButtonProps) => {
  const baseStyle = 'flex text-primary py-2 items-center justify-center gap-1'
  const borderedStyle = bordered && 'border border-primary rounded-full px-6 transition-colors duration-300'
  const primaryStyle =
    primary &&
    'bg-primary border border-primary rounded-full px-6 text-white hover:bg-[#FF915E] duration-150 active:bg-[#FE5000]'
  const disabledStyle =
    disabled &&
    (bordered
      ? 'bg-slate-200 border-slate-350 text-slate-350 cursor-not-allowed'
      : primary
        ? 'bg-slate-200 border-slate-350 text-slate-350 hover:bg-slate-200 active:bg-slate-200 cursor-not-allowed'
        : 'text-slate-350 cursor-not-allowed')

  const style = twMerge(baseStyle, borderedStyle, primaryStyle, disabledStyle, className)

  if (href)
    return (
      <Link
        onClick={onClick}
        target={target}
        className={twMerge(style, disabled && 'pointer-events-none')}
        href={disabled ? '#' : href}
      >
        {leftIcon}
        {children}
        {rightIcon}
      </Link>
    )

  return (
    <button type={type} className={style} onClick={onClick} disabled={disabled}>
      {leftIcon}
      {children}
      {rightIcon}
    </button>
  )
}

export default LinkButton
