import { ServerActionError } from '@/server-actions/wrapServerAction'
import { captureException } from '@sentry/browser'
import { ZodError } from 'zod'
import { create } from 'zustand'

export type StudioTrainState = {
  error: {
    friendlyError: string
    friendlyErrorDetail?: React.ReactNode
    displayError: string
    sentryId: string | null
    stack: string
  } | null
  warning: {
    friendlyWarning: string
  } | null
  setWarning: (props: { friendlyWarning: string }) => void
  setError: (props: { friendlyError: string; error: unknown }) => void
  resetError: () => void
  resetWarning: () => void
}

export const NO_VALUE = 'N/A'

const useStudioStore = create<StudioTrainState>()((set) => ({
  error: null,
  resetError: () => set({ error: null }),
  setError: ({ friendlyError, error }) => {
    // TODO: Capture error

    let displayError = 'An unexpected error occurred.'
    let friendlyErrorDetail = undefined
    let sentryId = null
    let stack = NO_VALUE

    if (error instanceof ServerActionError) {
      if (error.friendlyMessage) {
        friendlyErrorDetail = error.friendlyMessage
      }
      displayError = error.message
      sentryId = error.sentryId
      stack = error.stack || NO_VALUE
    } else if (error instanceof ZodError) {
      displayError = error.errors.map((err) => `${err.path.join('.')}: ${err.message}`).join('\n')
      sentryId = captureException(error)
    } else if (error instanceof Error) {
      displayError = error.message
      stack = error.stack || NO_VALUE
      sentryId = captureException(error)
    } else if (typeof error === 'string') {
      displayError = error
      sentryId = captureException(error)
    }

    set({ error: { friendlyError, friendlyErrorDetail, displayError, sentryId, stack } })
  },
  warning: null,
  setWarning: ({ friendlyWarning }) => {
    set({ warning: { friendlyWarning } })
  },
  resetWarning: () => set({ warning: null }),
}))

export default useStudioStore
