'use client'

import { useCallback, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { isKolzEnv } from '@/app/kolz/[replicaSlug]/utils'
import { Check, Copy } from '@phosphor-icons/react'
import { toast } from 'react-hot-toast'
import { twMerge } from 'tailwind-merge'

const CopiedButton = ({
  text,
  className,
  children,
  hasCopyIcon = true,
  isFullWidth = false,
}: { text: string; className?: string; children?: React.ReactNode; hasCopyIcon?: boolean; isFullWidth?: boolean }) => {
  const [isCopied, setIsCopied] = useState<boolean>(false)

  const onCopy = useCallback(() => {
    setIsCopied(true)
    const isKolz = isKolzEnv()

    toast.success('Copied!', {
      position: 'top-center',
      style: {
        padding: '16px',
        color: '#ffffff',
        background: isKolz ? '#3c7ac8' : '#FE8F5C',
        paddingBlock: '6px',
      },
      iconTheme: {
        primary: '#ffffff',
        secondary: isKolz ? '#3c7ac8' : '#FE8F5C',
      },
    })
    setTimeout(() => {
      setIsCopied(false)
    }, 2000)
  }, [])

  return (
    <CopyToClipboard text={isCopied ? ' ' : text} onCopy={onCopy}>
      <div className={twMerge('flex items-center', isFullWidth && 'w-full')}>
        {children}
        {hasCopyIcon &&
          (isCopied ? (
            <Check
              className={twMerge('h-4 w-4 cursor-pointer transition-opacity duration-300 hover:opacity-70', className)}
            />
          ) : (
            <Copy
              className={twMerge('h-4 w-4 cursor-pointer transition-opacity duration-300 hover:opacity-70', className)}
            />
          ))}
      </div>
    </CopyToClipboard>
  )
}

export default CopiedButton
