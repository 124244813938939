import type { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

type StudioSectionProps = {
  title?: string
  description?: string
  className?: string
  children: ReactNode
}

export default function StudioSection({ title, description, children, className }: StudioSectionProps) {
  return (
    <div className={twMerge('flex flex-col gap-y-6 p-6 border rounded-xl', className)}>
      {(title || description) && (
        <div>
          {title ? <h5 className="font-medium mb-2">{title}</h5> : null}
          {description ? <p>{description}</p> : null}
        </div>
      )}
      {children}
    </div>
  )
}
