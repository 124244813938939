'use client'
import { ArrowRight, CircleNotch } from '@phosphor-icons/react/dist/ssr'
import { useSession } from 'next-auth/react'
import { useState } from 'react'
import ToastMessage from 'react-hot-toast'
import { twMerge } from 'tailwind-merge'

function UserChatSuggestion({ replica }: { replica: string }) {
  const [userSuggestedQuestion, setUserSuggestedQuestion] = useState<string>('')
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const { data: session } = useSession()

  const saveUserSuggestion = async () => {
    setIsSaving(true)
    try {
      if (userSuggestedQuestion.length > 1) {
        await fetch('/api/suggestions/save', {
          method: 'POST',
          body: JSON.stringify({ question: userSuggestedQuestion, replica: replica }),
        })
        setUserSuggestedQuestion('')
        ToastMessage.success('Success!')
      } else {
        setError(true)
      }
    } catch (_error) {
      ToastMessage.error('Error')
    }
    setIsSaving(false)
  }

  return (
    session && (
      <div className="flex flex-col transition-all hover:-translate-x-2">
        <div className="relative">
          <input
            disabled={isSaving}
            value={userSuggestedQuestion}
            onChange={(e) => {
              setUserSuggestedQuestion(e.target.value)
              setError(false)
            }}
            placeholder="Suggest a question"
            className={twMerge(
              `w-fit select-none rounded-full rounded-tr-lg border border-primary/30
             bg-primary/10 px-3 py-1 pr-6 text-[#331c12] shadow-md duration-300 placeholder:text-black/50 last:mb-2 focus:outline-none`,
              error && 'border-red-500',
            )}
          />
          <button onClick={saveUserSuggestion} type="button">
            {!isSaving ? (
              <ArrowRight
                className="absolute right-[6px] top-1/2 h-4 w-4 -translate-y-1/2 transform cursor-pointer text-[#331c12]"
                weight="bold"
              />
            ) : (
              <CircleNotch
                size={20}
                className="absolute right-[6px] top-[8px] animate-spin  cursor-pointer rounded-full text-[#331c12]"
              />
            )}
          </button>
        </div>
        {error ? <p className="pl-[12px] text-sm text-red-500">Question can&apos;t be empty</p> : null}
      </div>
    )
  )
}

export default UserChatSuggestion
