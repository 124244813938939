'use client'

import { Content, Root, TooltipTrigger } from '@radix-ui/react-tooltip'
import React, { useState } from 'react'
import { twMerge } from 'tailwind-merge'

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof Content>,
  React.ComponentPropsWithoutRef<typeof Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <Content
    ref={ref}
    sideOffset={sideOffset}
    className={twMerge(
      'z-50 overflow-hidden rounded-md border px-3 py-1.5 text-sm bg-white data-[state=closed]:zoom-out-95',
      'animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0',
      className,
    )}
    {...props}
  />
))

interface TooltipProps {
  tooltip: React.ReactNode
  hideOnMobile?: boolean
  tooltipContentClassName?: string
  children: React.ReactElement
}

export function Tooltip({ children, tooltip, hideOnMobile, tooltipContentClassName }: TooltipProps) {
  const [open, setOpen] = useState(false)

  const isMobile = typeof window !== 'undefined' && ('ontouchstart' in window || navigator.maxTouchPoints > 0)

  if (isMobile && hideOnMobile) {
    return children
  }

  const handleMouseEnter = () => {
    if (tooltip) {
      setOpen(true)
    }
  }

  const handleMouseLeave = () => {
    setOpen(false)
  }

  return (
    <div
      onClick={handleMouseEnter}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="contents"
    >
      <Root open={open}>
        <TooltipTrigger asChild>{children}</TooltipTrigger>
        <TooltipContent className={tooltipContentClassName}>{tooltip}</TooltipContent>
      </Root>
    </div>
  )
}
