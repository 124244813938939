export const ALLOWED_KOLZ_REPLICAS = [
  'evanluthra',
  'the-crypto-lord',
  'kong-trading',
  'vass',
  'samurai',
  'th3gabo',
  'cyril',
  'mando-ct',
  'wsb-trader-rocko',
  'jacktherippler',
  '1minute',
  'bullish-banter',
  'alan-rogers',
  'satoshi-club',
  'prime',
  'steven-crypto-research',
  'lluciano_btc',
  'bitcoin-buddha',
  'youngest-og',
  'pumpbob',
  'table',
  'ansem',
  'mario-nawfal',
  'pegasus-kripto',
  'jesse',
  'blockchainballer',
  'quinten',
]

export const isKolzEnv = () =>
  typeof window !== 'undefined' &&
  // in local and sensay, we are using /kolz path, whereas in kolz.chat we're reverse proxying to kolz.chat/replica-id => with points to sensay.io/kolz/replica-id
  // so we need to support both cases
  (window.location.pathname.startsWith('/kolz') ||
    window.location.host === 'kolz.chat' ||
    window.location.host === 'kolz-www-nextjs.vercel.app')
