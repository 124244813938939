import { kv } from '@vercel/kv'

const SEVEN_DAYS_IN_SECONDS = 60 * 60 * 24 * 7

export const NAMESPACE_CUSTOMERS = 'customers'
export const NAMESPACE_CUSTOMERS_TEST = `${NAMESPACE_CUSTOMERS}:test`
export const NAMESPACE_CUSTOMERS_LIVE = `${NAMESPACE_CUSTOMERS}:live`

export const NAMESPACE_CUSTOMERS_IDS = 'customers_ids'
export const NAMESPACE_CUSTOMERS_IDS_LIVE = `${NAMESPACE_CUSTOMERS_IDS}:live`
export const NAMESPACE_CUSTOMERS_IDS_TEST = `${NAMESPACE_CUSTOMERS_IDS}:test`

export const NAMESPACE_KPIS = 'kpis'

export function initKv<T = { [field: string]: unknown }>(namespace: string, ttl = SEVEN_DAYS_IN_SECONDS) {
  return {
    get: async (key: string) => {
      return kv.get<T>(`${namespace}:${key}`)
    },
    set: async (key: string, value: T) => {
      return kv.set<T>(`${namespace}:${key}`, value, { ex: ttl })
    },
    del: async (key: string) => {
      return kv.del(`${namespace}:${key}`)
    },
  }
}

/**
 * Sample usage:
 * const { get, set } = initKv<{ name: string }>('user', optionalTtlInSeconds)
 *
 * const sessionUser = await get('user1')
 *
 * await set('userId', { asd: 'John' })
 */
